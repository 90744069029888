// assets
import { IconDashboard, IconDeviceAnalytics, IconServer, IconUser } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconUser,
    IconServer
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'general',
            title: 'Général',
            type: 'item',
            url: '/dashboard/general',
            icon: icons['IconDashboard'],
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/dashboard/user',
            icon: icons['IconUser'],
            breadcrumbs: false
        },
        {
            id: 'aws',
            title: 'AWS',
            type: 'item',
            url: '/dashboard/aws',
            icon: icons['IconServer'],
            breadcrumbs: false
        }
    ]
};
