let __DEV__ = true

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard/general',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    API_SERVER: 'http://localhost:5000/api/',
    s3: {
        REGION: "eu-west-1",
        BUCKET: __DEV__ ? "dev-pulse-back-storage-pulsesvideosbucket18edae8d-nzhw8jcrnuo6" : "prod-pulse-back-storage-pulsesvideosbucket18edae8-1bczrkt2jgsiq",
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: __DEV__ ? "https://dev.pulse-app.fr/" : "https://api.pulse-app.fr/",
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: __DEV__ ? "eu-west-1_V7ZrMA4lo" : "eu-west-1_9k0fs8C3X",
        APP_CLIENT_ID: __DEV__ ? "78uecn64n8uhtqnsbeid17n13a" : "3j8q80egpl4ual5nrrer0ggeml",
        IDENTITY_POOL_ID: __DEV__ ? "eu-west-1:ebdd5f85-22f3-4d08-80c6-513459bf867f" : "eu-west-1:5028279b-efc4-4e77-9ebe-3c15926fad17",
    },
};

export default config;
