import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardGeneral = Loadable(lazy(() => import('../views/dashboard/general')));
const DashboardUser = Loadable(lazy(() => import('../views/dashboard/user')));
const DashboardAWS = Loadable(lazy(() => import('../views/dashboard/aws')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

const UsersAll = Loadable(lazy(() => import('../views/users/all/index')));
const UsersMap = Loadable(lazy(() => import('../views/users/map/index')));
const UsersLook = Loadable(lazy(() => import('../views/users/look/index')));
const UsersNotifications = Loadable(lazy(() => import('../views/users/notifications/index')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/general',
                '/dashboard/user',
                '/dashboard/aws',

                '/users/all',
                '/users/look',
                '/users/notifications',
                '/users/map',
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/general" component={DashboardGeneral} />
                        <Route path="/dashboard/user" component={DashboardUser} />
                        <Route path="/dashboard/aws" component={DashboardAWS} />

                        <Route path="/users/all" component={UsersAll} />
                        <Route path="/users/look" component={UsersLook} />
                        <Route path="/users/notifications" component={UsersNotifications} />
                        <Route path="/users/map" component={UsersMap} />

                        <Route path="/sample-page" component={SamplePage} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
