// assets
import { IconBrandFramer, IconTypography, IconPalette, IconShadow, IconMap, IconWindmill, IconLayoutGridAdd, IconUserSearch, IconBell, IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconUserSearch,
    IconBell,
    IconUsers,
    IconMap
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const utilities = {
    id: 'utilities',
    title: 'Utilisateurs',
    type: 'group',
    children: [
        // {
        //     id: 'users-all',
        //     title: 'All',
        //     type: 'item',
        //     url: '/users/all',
        //     icon: icons['IconUsers'],
        //     breadcrumbs: false
        // },
        {
            id: 'users-look',
            title: 'Lockup',
            type: 'item',
            url: '/users/look',
            icon: icons['IconUserSearch'],
            breadcrumbs: false
        },
        {
            id: 'users-notifs',
            title: 'Notifications',
            type: 'item',
            url: '/users/notifications',
            icon: icons['IconBell'],
            breadcrumbs: false
        },
        {
            id: 'users-map',
            title: 'Carte',
            type: 'item',
            url: '/users/map',
            icon: icons['IconMap'],
            breadcrumbs: false
        },
    ]
};
